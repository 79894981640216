import { CSSProperties, FC } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Typography from '@/ui/components/typography';

const HeaderRenderer: FC<{
    data: string | { text: string, level: number },
    style?: CSSProperties,
    classNames?: string
}> = ({
    data,
    style,
    classNames,
}) => {
    let content = null;

    if (typeof data === 'string') {
        content = data;
    } else if (typeof data === 'object' && data.text) {
        content = data.text;
    }
    if (!content) {
        return null;
    }
    if (typeof data === 'object' && data.level) {
        switch (data.level) {
            case 1:
                return (
                    <Typography
                        as="h1"
                        className={classNames}
                        style={style}
                        variant="h4"
                    >{ReactHtmlParser(content)}
                    </Typography>
                );
            case 2:
                return (
                    <div id={content}>
                        <Typography
                            as="h2"
                            className={classNames}
                            style={style}
                            variant="h4"
                        >{ReactHtmlParser(content)}
                        </Typography>
                    </div>
                );
            case 3:
                return (
                    <Typography
                        as="h3"
                        className={classNames}
                        style={style}
                        variant="h5"
                    >{ReactHtmlParser(content)}
                    </Typography>
                );
            case 4:
                return (
                    <Typography
                        as="h4"
                        className={classNames}
                        style={style}
                        variant="h6"
                    >{ReactHtmlParser(content)}
                    </Typography>
                );
            case 5:
                return (
                    <Typography
                        as="h5"
                        className={classNames}
                        style={style}
                        variant="h7"
                    >{ReactHtmlParser(content)}
                    </Typography>
                );
            case 6:
                return (
                    <Typography
                        as="h6"
                        className={classNames}
                        style={style}
                        variant="h7"
                    >{ReactHtmlParser(content)}
                    </Typography>
                );
            default:
                return (
                    <Typography
                        as="h4"
                        className={classNames}
                        variant="h4"
                    >{ReactHtmlParser(content)}
                    </Typography>
                );
        }
    }

    return (
        <Typography
            as="h4"
            className={classNames}
            variant="h4"
        >{ReactHtmlParser(content)}
        </Typography>
    );
};

export default HeaderRenderer;
